import { ServiceDialog } from './../../service-dialog/service-dialog';
import { ServiceReportService } from './../../../services/service-report-service';
import { I18N } from 'aurelia-i18n';
import { ErrorService } from 'services/error-service';
import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';

const logger: Logger = LogManager.getLogger("backlog-details-dialog");

@autoinject
export class BacklogDetailsDialog {
    private serviceFilter;
    private services: Array<any>; 

    constructor(private dialogController: DialogController,
        private serviceReportService: ServiceReportService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private i18n: I18N) {                 
    }

    activate(serviceFilter) {
        this.serviceFilter = serviceFilter;
logger.debug('serviceFilter', this.serviceFilter);
        this.serviceReportService.getServiceStationBacklogDetails(
            serviceFilter.serviceStationId, 
            serviceFilter.taskCode, 
            serviceFilter.isSpecialProduct,                        
            serviceFilter.netTypeId,
            serviceFilter.specialProductTypeId,
            serviceFilter.requirePlannedDeliveryDate
        ).then(services => {
            this.services = services;
        })
        .catch(err => {
            this.errorService.handleError(err);
            this.services = [];
        });
    }

    openService(service) {
        this.dialogService.open({ viewModel: ServiceDialog, model: { Id: service.ServiceId, NetId: service.NetId }, lock: false, position: (mc, mo) => {} })
            .whenClosed(x => {
                document.querySelector('html').style.overflowY = null;        
            }).catch((e) => {
                document.querySelector('html').style.overflowY = null;
            });
    }
}
