import { ServiceDialog } from './../../service-dialog/service-dialog';
import { ServiceReportService } from './../../../services/service-report-service';
import { I18N } from 'aurelia-i18n';
import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { ServiceReportDetail } from 'models/service-report-models';

@autoinject
export class MonthReportDetailsDialog {
  private serviceFilter;
  private services: Array<any>;

  constructor(
    private dialogController: DialogController,
    private serviceReportService: ServiceReportService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private i18n: I18N
  ) {}

  activate(serviceFilter) {
    this.serviceFilter = serviceFilter;
    this.serviceReportService
      .getServiceStationMonthReportDetails(
        serviceFilter.serviceStationId,
        serviceFilter.year,
        serviceFilter.month,
        serviceFilter.task,
        serviceFilter.type,
        serviceFilter.netTypeId,
        serviceFilter.specialProductTypeId
      )
      .then((services) => {
        this.services = services;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.services = [];
      });
  }

  openService(service: ServiceReportDetail) {
    if (!service.ServiceId) {
      const route = !service.IsSpecialProduct
        ? 'net-detail/' + service.NetId
        : 'special-product-detail/' + service.NetId;

      window.open('#/' + route, '_blank');
    } else {
      this.dialogService
        .open({
          viewModel: ServiceDialog,
          model: { Id: service.ServiceId, NetId: service.NetId },
          lock: false,
          position: () => {
            /*Need to be here*/
          },
        })
        .whenClosed(() => {
          document.querySelector('html').style.overflowY = null;
        })
        .catch(() => {
          document.querySelector('html').style.overflowY = null;
        });
    }
  }
}
