import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class RecyclingCompanyForm {
  @bindable() private recyclingCompany: Models.RecyclingCompany;

  constructor(
    private errorService: ErrorService,
    private productService: ProductService
  ) { }

}
