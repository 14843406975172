import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, containerless } from 'aurelia-framework';
import { bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NettingTypeService } from 'services/netting-type-service';
import { ReinforcementMountingService } from 'services/reinforcement-mounting-service';
import { ReinforcementService } from 'services/reinforcement-service';
import { ReinforcementTypeService } from 'services/reinforcement-type-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class ReinforcementFormInlineNew {
  @bindable private netId: number;
  @bindable private nettings: Models.Netting[];
  private reinforcement: Models.Reinforcement;

  protected nettingStrengthLowerThenRequirements = false;
  protected nettingMissingActualBreakingStrength = false;

  constructor(
    private reinforcementService: ReinforcementService,
    private eventAggregator: EventAggregator,
    private toastService: ToastService,
    private errorService: ErrorService,
    protected reinforcementTypeService: ReinforcementTypeService,
    protected nettingTypeService: NettingTypeService,
    protected reinforcementMountingService: ReinforcementMountingService
  ) {}

  protected attached() {
    this.reinforcement = this.createNewReinforcementWithDefaults();
  }

  protected createReinforcement() {
    this.reinforcement.NetId = this.netId;

    this.reinforcement.NettingType = null;
    this.reinforcement.ReinforcementType = null;
    this.reinforcement.ReinforcementMounting = null;

    this.reinforcementService
      .post(this.reinforcement)
      .then(() => {
        this.eventAggregator.publish('reinforcementListReset', 1);
        this.eventAggregator.publish('reinforcementFormNewClose', 1);
        this.toastService.showSuccess('reinforcement.created');
        this.reinforcement = this.createNewReinforcementWithDefaults();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  async meterAboveRopeChanged(newValue?: number) {
    if (!newValue) return;

    await this.setDefaultNettingType();
  }

  async meterBelowRopeChanged(newValue?: number) {
    if (!newValue) return;

    await this.setDefaultNettingType();
  }

  async meterFromCenterChanged(newValue?: number) {
    if (!newValue) return;

    await this.setDefaultNettingType();
  }

  async setDefaultNettingType() {
    if (this.reinforcement.NettingTypeId) return;

    const defaultNetting = this.nettings[0];
    if (defaultNetting) {
      this.reinforcement.NettingTypeId = defaultNetting.NettingTypeId;

      const nettingType = await this.nettingTypeService.get(defaultNetting.NettingTypeId + '?$expand=MeshSize,NavisionProduct');
      const strengthRule = await this.reinforcementService.getReinforcementStrengthRuleByNetId(this.netId, nettingType.MeshSize.MeshSizeMm)

      this.reinforcement.MinBreakingStrength = strengthRule.StrengthRequirement;
      this.reinforcement.ActualBreakingStrength = nettingType.NavisionProduct.MinBreakingStrength;

      this.trySetRequirementsWarning(this.reinforcement.ActualBreakingStrength, strengthRule.StrengthRequirement)
    }
  }

  trySetRequirementsWarning(actualBreakingStrength: number, strengthRequirement: number) {
    if (actualBreakingStrength && strengthRequirement && actualBreakingStrength < strengthRequirement) {
      this.nettingStrengthLowerThenRequirements = true;
    } else {
      this.nettingStrengthLowerThenRequirements = false;
    }

    if (!actualBreakingStrength) {
      this.nettingMissingActualBreakingStrength = true;
    } else {
      this.nettingMissingActualBreakingStrength = false;
    }
  }

  protected async changeNettingType(event: any) {
    if (!event.detail.value || event.detail.value === this.reinforcement.NettingTypeId) {
      return;
    }

    const nettingTypeId = +event.detail.value;
    this.reinforcement.NettingTypeId = nettingTypeId;

    const nettingType = await this.nettingTypeService.get(nettingTypeId + '?$expand=MeshSize,NavisionProduct');
    const strengthRule = await this.reinforcementService.getReinforcementStrengthRuleByNetId(
      this.netId,
      nettingType.MeshSize.MeshSizeMm
    );

    this.reinforcement.MinBreakingStrength = strengthRule.StrengthRequirement;
    this.reinforcement.ActualBreakingStrength = nettingType.NavisionProduct.MinBreakingStrength;
    this.reinforcement.MeshSize = nettingType.MeshSize.MeshSizeMm;
    this.reinforcement.Omfar = nettingType.Omfar;
    this.reinforcement.Solidity = nettingType.Solidity;
    this.reinforcement.MeshOpening = nettingType.MeshOpening;

    this.trySetRequirementsWarning(this.reinforcement.ActualBreakingStrength, strengthRule.StrengthRequirement);
  }

  private createNewReinforcementWithDefaults(): Models.Reinforcement {
    const reinforcement = new Models.Reinforcement();
    reinforcement.ReinforcementMountingId = 1;
    return reinforcement;
  }

  protected cancel() {
    this.eventAggregator.publish('reinforcementFormNewClose', 1);
  }
}
