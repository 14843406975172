import { autoinject, observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { NewnetReportService } from 'services/newnet-report-service';
import { ProducerService } from 'services/producer-service';
import { Models } from 'models/core';
import * as moment from 'moment';
import { PaginationHandler } from '../../elements/pagination-handler';
import { MaterialService } from 'services/material-service';
import { ColumnFilter } from 'lib/tables/ColumnFilter';
import { DesignTypeService } from 'services/design-type-service';

const SELECTED_NEWNETMONTHREPORT_COLUMNS = 'SELECTED_NEWNETMONTHREPORT_COLUMNS';

export interface Filters {
  orderBy: string;
  orderTop: number;
  orderByDirection: 'DESC' | 'ASC';
  top: number;
  skip: number;
  fromDate?: string;
  toDate?: string;
  productionLocationIds?: string;
  materialIds?: string;
  designTypeIds?: string;
}

type Column = ColumnFilter<Models.NewNetReport>;
type ColumnFilters = Column[];

@autoinject
export class newnetMonthReportService {
  // LOCAL STORAGE KEY FOR SAVING FILTERS
  private NET_FILTERS_KEY = 'NEW_NET_MONTH_REPORT_FILTERS_KEY';

  private netFilters: Filters = {
    orderBy: '',
    orderTop: this.pagination.pageSize ?? 0,
    orderByDirection: 'DESC',
    top: 0,
    skip: 0,
  };

  @observable({ changeHandler: 'onProducerIdChanged' })
  producerId: number;
  onProducerIdChanged(newId?: string) {
    if (this.netFilters.productionLocationIds == newId) return;

    this.netFilters.productionLocationIds = newId ?? undefined;
    this.doSaveStateAndUpdateReportData();
  }

  @observable({ changeHandler: 'onNetDesignIdChanged' })
  netDesignId: number;
  onNetDesignIdChanged(newId?: string) {
    if (this.netFilters.designTypeIds == newId) return;

    this.netFilters.designTypeIds = newId ?? undefined;
    this.doSaveStateAndUpdateReportData();
  }

  @observable({ changeHandler: 'onNetMaterialIdChanged' })
  netMaterialId: number;
  onNetMaterialIdChanged(newId?: string) {
    if (this.netFilters.materialIds == newId) return;

    this.netFilters.materialIds = newId ?? undefined;
    this.doSaveStateAndUpdateReportData();
  }

  @observable({ changeHandler: 'onDateFromChanged' })
  dateFrom: Date = null;
  onDateFromChanged(newDate?: Date) {
    const newDateValue = this.getFilterDate(newDate);
    if (newDateValue === this.netFilters.fromDate) return;

    this.netFilters.fromDate = newDateValue;
    this.doSaveStateAndUpdateReportData();
  }

  @observable({ changeHandler: 'onDateToChanged' })
  dateTo: Date = null;
  onDateToChanged(newDate?: Date) {
    const newDateValue = this.getFilterDate(newDate);
    if (newDateValue === this.netFilters.toDate) return;

    this.netFilters.toDate = newDateValue;
    this.doSaveStateAndUpdateReportData();
  }

  private getFilterDate(newDate?: Date) {
    if (newDate && newDate instanceof Date) {
      return moment.default(newDate).format('YYYY-MM-DD');
    }
    return undefined;
  }

  newnetFilterGetFiltersQuery: any;

  netProducers: Models.Producer[];

  exportMonthReportProgress = false;

  selectedFields: Array<any>;

  monthReport: Array<any>;

  private hasLoadedData = false;

  private context: string;

  private fields: ColumnFilters = DEFAULT_COLUMNS;

  constructor(
    private eventAggregator: EventAggregator,
    private newnetReportService: NewnetReportService,
    private errorService: ErrorService,
    private producerService: ProducerService,
    private pagination: PaginationHandler,
    private materialService: MaterialService,
    private designTypeService: DesignTypeService,
    private router: Router
  ) {
    //Set the default period to the previous month
    this.dateFrom = moment.default().subtract(1, 'months').startOf('month').toDate();
    this.dateTo = moment.default().subtract(1, 'months').endOf('month').toDate();

    const selectedFields: Array<string> = JSON.parse(localStorage.getItem(SELECTED_NEWNETMONTHREPORT_COLUMNS));

    if (selectedFields?.length) {
      // deselect all fields, then reselect the selected fields
      this.fields.forEach((field) => {
        // hidden fields are not deselected, because this can cause problems
        // when adding new hidden fields needed for system purposes
        if (!field.visible) {
          field.selected = false;
        }
      });

      this.fields.forEach((field) => {
        const selectedField = selectedFields.find((x) => x == field.field);
        if (selectedField) {
          field.selected = true;
        } else {
          if (field.visible || field.visible == undefined) {
            field.selected = false;
          }
        }
      });
    }
  }

  /**
   * Gets all net producers and sets local variable.
   * The results removes deleted producers and only selects morenot companies.
   */
  async getNetProducers() {
    const producers = (await this.producerService.getAllCached()).filter((x) => !x.IsDeleted && x.IsMorenotCompany);

    this.netProducers = producers;
  }

  updateNewnetMonthReport() {
    if (!this.hasLoadedData) {
      return;
    }
    sessionStorage.setItem(
      'newnet-month-report',
      JSON.stringify({
        serviceStationId: this.producerId,
        dateTo: this.dateTo,
        dateFrom: this.dateFrom,
      })
    );
    this.getMonthReport();
  }

  async activate(params: { currentPage?: number }) {
    this.pagination.init({
      currentPage: params.currentPage,
      totalItems: 0,
      onPageChanged: () => this.getMonthReport(),
    });

    await this.getNetProducers();
    await this.getMonthReport();
    await this.materialService.getAllCached();
  }

  async attached() {
    this.router.currentInstruction.config.name === await this.context;
  }

  async getMonthReport() {
    try {
      this.netFilters = { ...this.netFilters, ...this.pagination.pagination };

      this.selectedFields = this.fields.filter((x) => x.selected && (x.visible === undefined || x.visible === true));

      const response = await this.newnetReportService.getNewnetMonthReportList(this.netFilters);

      this.pagination.totalItems = Number.parseInt(response.headers.get('x-total-count'));

      this.monthReport = (await response.json()) as Models.NewNetReport[];

      this.hasLoadedData = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  /**
   * Sorts the new net table by the provided field.
   * It sorts by the fields name, and if it is the current sorted field
   * sets the direction to the oposite of the current -> ASC becomes DESC or vice versa.
   * Default direction is DESC.
   */
  private sortTable(fieldName: string) {
    if (!this.netFilters) {
      return;
    }

    if (this.netFilters.orderBy === fieldName) {
      this.netFilters.orderByDirection = this.netFilters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.netFilters.orderBy = fieldName;
      this.netFilters.orderByDirection = 'DESC';
    }
    this.doSaveStateAndUpdateReportData();
  }

  private openNewTab(fieldName: string, row: Models.NewNetReport) {
    if (!this.netFilters) {
      return;
    }

    if (fieldName == 'NetIdentifier') {
      window.open(this.router.generate('net-detail', { Id: row.NetId }));
    } else if (fieldName == 'QuoteNumber') {
      window.open(
        this.router.generate(row.IsSimpleOffer ? 'simple-offer-detail' : 'offer-detail', {
          Id: row.NetOfferId,
          NetId: row.NetId,
        })
      );
    } else if (fieldName == 'OrderNumber') {
      window.open(this.router.generate('order-detail', { Id: row.OrderId, NetId: row.NetId }));
    }
  }
  /**
   * Saves the selected filters to local storage
   */
  private saveFilters() {
    localStorage.setItem(this.NET_FILTERS_KEY, JSON.stringify(this.netFilters));
  }

  private doSaveStateAndUpdateReportData() {
    this.saveFilters();
    this.getMonthReport();
  }

  private closeDropdown() {
    this.eventAggregator.publish('dropdownClose', 1);
  }

  closeFieldSelector() {
    this.closeDropdown();
  }

  /**
   * Starts the export of the month report with the
   * selected columns and filters.
   */
  async exportMonthReport() {
    try {
      this.exportMonthReportProgress = true;

      const fields = this.fields.filter((x) => x.selected && !(x.exportable === false)).map((x) => x.field);

      const selectedFields = fields.join(',');

      await this.newnetReportService.getNewnetMonthReportList(this.netFilters, true, selectedFields);
    } catch (error) {
      this.errorService.handleError(error);
    } finally {
      this.exportMonthReportProgress = false;
    }
  }

  updateSelectList() {
    const selectedFields = this.fields.filter((x) => x.selected).map((x) => x.field);
    localStorage.setItem(
      SELECTED_NEWNETMONTHREPORT_COLUMNS + (this.context ? '_' + this.context : ''),
      JSON.stringify(selectedFields)
    );

    this.getMonthReport();
  }
}

const DEFAULT_COLUMNS: ColumnFilters = [
  {
    field: 'CustomerName',
    title: 'reports.newnetmonthreport.customername',
    selected: true,
    visible: true,
    exportable: true
  },
  {
    field: 'NetIdentifier',
    title: 'reports.newnetmonthreport.netid',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'QuoteNumber',
    title: 'reports.newnetmonthreport.offernumber',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'OrderNumber',
    title: 'reports.newnetmonthreport.ordernumber',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'ProductionDateFrontEnd',
    title: 'reports.newnetmonthreport.productiondate',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'DesignType',
    title: 'reports.newnetmonthreport.designtype',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'ProductionPlace',
    title: 'reports.newnetmonthreport.productionplace',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'Material',
    title: 'reports.newnetmonthreport.material',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'TotalLength',
    title: 'reports.newnetmonthreport.ropelength',
    selected: false,
    visible: true,
    exportable: true,
  },
  {
    field: 'Nettype',
    title: 'reports.newnetmonthreport.nettype',
    selected: false,
    visible: true,
    exportable: true,
  },
  {
    field: 'TotalDepth',
    title: 'reports.newnetmonthreport.totaldepth',
    selected: false,
    visible: true,
    exportable: true,
  },
  {
    field: 'Netting',
    title: 'reports.newnetmonthreport.netting',
    selected: false,
    visible: true,
    exportable: true,
  },
  {
    field: 'PlannedHours',
    title: 'reports.newnetmonthreport.estimatedhours',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'ActualHours',
    title: 'reports.newnetmonthreport.actualhours',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'HoursDifference',
    title: 'reports.newnetmonthreport.difference',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'TypeImpregnation',
    title: 'reports.newnetmonthreport.typeimpregnation',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'EstimatedImpregnation',
    title: 'reports.newnetmonthreport.estimatedimpregnation',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'ActualImpregnation',
    title: 'reports.newnetmonthreport.actualimpregnation',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'ImpregnationDifference',
    title: 'reports.newnetmonthreport.difference',
    selected: true,
    visible: true,
    exportable: true,
  },
  {
    field: 'ImpregnationAmountFactor',
    title: 'reports.newnetmonthreport.factor',
    selected: true,
    visible: true,
    exportable: true,
  },
];
