import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { RecyclingCompanyService } from 'services/recycling-company-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class RecyclingCompanyNew {
  private recyclingCompany: Models.RecyclingCompany = new Models.RecyclingCompany();

  constructor(
    private recyclingCompanyService: RecyclingCompanyService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  private createRecyclingCompany() {
    this.recyclingCompanyService
      .post(this.recyclingCompany)
      .then((res) => {
        this.eventAggregator.publish('recyclingCompanyListReset', 1);
        this.toastService.showSuccess('recyclingCompany.created');
        this.router.navigateToRoute('recycling-company-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
