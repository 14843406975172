import { Models } from "models/core";
import { Router } from 'aurelia-router';
import { ProducerService } from "services/producer-service";
import { MaterialService } from "services/material-service";
import { NetTypeService } from "services/net-type-service";
import { DesignTypeService } from "services/design-type-service";
import { autoinject } from "aurelia-framework";
import { NewnetAntifoulingReportService } from "services/newnet-antifouling-report-service";
import { ErrorService } from "services/error-service";
import { Filters as newFilter, filterTypes } from "../../elements/Filter";
import { I18N } from "aurelia-i18n";


const GetCallbackData = <T>(cb: () => Promise<T>) => {
    return new Promise ((res: (i: T) => unknown) => {
        setTimeout(async () => {
            res(await cb())
        })
    })
}
export interface Filters {
    orderBy: string;
    orderByDirection: 'DESC' | 'ASC';
}

@autoinject 
export class newNetAntifouling {

    private filter: Filters = {
        orderBy: 'Name',
        orderByDirection: 'ASC'
    } 

    isLoading = false;
    
    private ANTIFOULING_FILTERS_KEY = 'NEW_NET_ANTIFOULING_FILTERS_KEY';

    producers: Models.Producer[];

    antifoulingReport: Array<any>;

    constructor(
        private producerService: ProducerService,
        private materialService: MaterialService,
        private netTypeService: NetTypeService,
        private designTypeService: DesignTypeService,
        private newnetAntifoulingReportService: NewnetAntifoulingReportService,
        private errorService: ErrorService,
        private router: Router,
        private translator: I18N
    ){
    }

    protected context: string;
    protected filterGetFiltersQuery: () => Promise<any>
    protected filterClearAll: () => void;
    protected filterClearSingle: (name: string) => void;
    protected filterToggleVisible: () => void;
    protected setFilterValueByName: (name: string, data: any) => void; 


    protected defaultFilters = {
        "producerIds": true,
        "dateFrom": true,
        "materialIds": true,
        "netTypeIds": true,
        "designTypeIds": true
    }

    async getProducers() {
        const producers = (await this.producerService.getAllCached()).filter((x) => !x.IsDeleted && x.IsMorenotCompany);

        this.producers = producers;   
    }

    async activate(){
        await this.setupFilters();
        await this.getAntifoulingReport(); 
    }

    async getAntifoulingReport() {
        try{
            this.isLoading = true;

            setTimeout(async() => {
                const filter = await this.getFilterValues();
                const allFilters = {...filter, ...this.filter}
                const response = await this.newnetAntifoulingReportService.getNewNetAntifoulingReportList(allFilters)
                this.antifoulingReport = (await response.json() as Models.NewNetAntifoulingReport[]);
                this.isLoading = false;
            })
        } catch (error) {
            this.errorService.handleError(error);
        }
    }

    protected onFilterChanged() {
        void this.getAntifoulingReport();
    }

    private sortTable (fieldName: string) {
        if (!this.filter){
            return;
        }

        if (this.filter.orderBy === fieldName) {
            this.filter.orderByDirection = this.filter.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
        } else {
            this.filter.orderBy = fieldName;
            this.filter.orderByDirection = 'DESC';
        }
        this.doSaveStateAndUpdateReportDate();
    }
    
    private doSaveStateAndUpdateReportDate () {
        this.saveFilters();        
        this.getAntifoulingReport();
    }

    private saveFilters() {
        localStorage.setItem(this.ANTIFOULING_FILTERS_KEY, JSON.stringify(this.filter))
    }

    protected getFilterKey() {
        return 'NEW_NET_ANTIFOULING_FILTERS_KEY'
    }

    getFilterValues() {
        return GetCallbackData(this.filterGetFiltersQuery);
    }

    protected async setupFilters () {

        return await new Promise((res: (v: newFilter) => void) => {
            setTimeout(async () => {

                const producersJob = (await this.producerService.getAllCached()).filter((x) => !x.IsDeleted && x.IsMorenotCompany);
                const materialsJob = (await this.materialService.getAllCached());
                const netTypesJob = (await this.netTypeService.getAllCached());
                const designTypesJob = (await this.designTypeService.getAllCached());

                const [producers, materials, netTypes, designTypes] = await Promise.all([producersJob, materialsJob, netTypesJob, designTypesJob])

                const filters: newFilter = {
                    producerIds: {
                        name: 'producerIds',
                        label: this.translator.tr('general.producer'),
                        type: filterTypes.CHECKBOX,
                        options: producers,
                        query: 'producerIds'
                    },
                    dateFrom: {
                        name: 'deliveryDate',
                        label: this.translator.tr('general.deliveryDate'),
                        type: filterTypes.RANGE_DATE,
                    },
                    materialIds: {
                        name: 'materialIds',
                        label: this.translator.tr('netting.material'),
                        type: filterTypes.CHECKBOX,
                        options: materials,
                        query: 'materialIds'
                    },
                    netTypeIds: {
                        name: 'netTypeIds',
                        label: this.translator.tr('general.NetType'),
                        type: filterTypes.CHECKBOX,
                        options: netTypes,
                        query: 'netTypeIds'
                    },
                    designTypeIds: {
                        name: 'designTypeIds',
                        label: this.translator.tr("notlogg.designtype"),
                        type: filterTypes.CHECKBOX,
                        options: designTypes,
                        query: 'designTypeIds'
                    },
                };

                res(filters);

            });
        });
    }
}
